<template>
  <div class="reset">
    <van-tabs v-model="active">
      <van-tab title="最新">
        <van-cell-group>
          <van-cell
            class="css2"
            title="1"
            value="内容"
            label="1"
            v-for="(item, index) in TableData"
            :key="index"
            @click="fileClick(item)"
          >
            <template slot="title"
              ><span class="css1"
                >【<span
                  v-if="
                    item.readStatus == '已学习' || item.readStatus == '已读'
                  "
                  style="color: #28a745"
                  >{{ item.readStatus }}</span
                ><span
                  v-if="
                    item.readStatus == '未学习' || item.readStatus == '未读'
                  "
                  style="color: #ebb563"
                  >{{ item.readStatus }}</span
                >】 {{ item.title }}</span
              >
            </template>
            <template slot="label"
              ><span class="css2">{{ item.content }}</span></template
            >
            <template slot="default"
              ><span
                >{{ item.type }} | {{ item.pubDateStr }} |
                {{ item.postField }} | 附件X{{ item.attachmentCount }}</span
              >
            </template>
          </van-cell>
        </van-cell-group>
      </van-tab>
      <van-tab title="未学习">
        <van-cell-group>
          <van-cell
            class="css2"
            title="1"
            value="内容"
            label="1"
            v-for="(item, index) in TableData2"
            :key="index"
            @click="fileClick(item)"
          >
            <template slot="title"
              ><span class="css1"
                >【<span style="color: #ebb563">{{ item.readStatus }}</span
                >】 {{ item.title }}</span
              >
            </template>
            <template slot="label"
              ><span class="css2">{{ item.content }}</span></template
            >
            <template slot="default"
              ><span
                >{{ item.type }} | {{ item.pubDateStr }} |
                {{ item.postField }} | 附件X{{ item.attachmentCount }}</span
              >
            </template>
          </van-cell>
        </van-cell-group>
      </van-tab>
      <van-tab title="已学习">
        <van-cell-group>
          <van-cell
            class="css2"
            title="1"
            value="内容"
            label="1"
            v-for="(item, index) in TableData3"
            :key="index"
            @click="fileClick(item)"
          >
            <template slot="title"
              ><span class="css1"
                >【<span style="color: #28a745">{{ item.readStatus }}</span
                >】 {{ item.title }}</span
              >
            </template>
            <template slot="label"
              ><span class="css2">{{ item.content }}</span></template
            >
            <template slot="default"
              ><span
                >{{ item.type }} | {{ item.pubDateStr }} |
                {{ item.postField }} | 附件X{{ item.attachmentCount }}</span
              >
            </template>
          </van-cell>
        </van-cell-group>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import { Notify, Button, Field, Form, Tab, Tabs, Cell, CellGroup } from "vant";
import { getPageApi } from "@/api/rules/rules";
import { getunstudypageApi } from "@/api/rules/rules";
import { getstudiedpageApi } from "@/api/rules/rules";
import { setToken } from "@/utils/auth";
import { getsignin } from "@/api/activity/activity";

// import { getCode } from "@/utils/common";
export default {
  name: "resetPassword",
  components: {
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Notify.name]: Notify,
    [Button.name]: Button,
    [Field.name]: Field,
    [Form.name]: Form,
  },

  data() {
    return {
      isLoading: false,
      TableData: [],
      TableData2: [],
      TableData3: [],
      isShow: false,
      formData: {
        userName: "",
        // phone:"",
        position: "",
        currentstationName: "",
      },
    };
  },
  mounted() {
    this.getDetails();
  },
  created() {},
  methods: {
    getDetails() {
      getPageApi().then((res) => {
        this.TableData = res.data.listData;
        console.log(res);
      });
      getunstudypageApi().then((res) => {
        this.TableData2 = res.data.listData;
        console.log(res);
      });
      getstudiedpageApi().then((res) => {
        this.TableData3 = res.data.listData;
        console.log(res);
      });
      let ref = window.location.href;
      if (ref.indexOf("?p=") != -1) {
        let token1 = ref.substring(ref.indexOf("?p=") + 3);
        setToken(token1);
      }
    },
    fileClick(row) {
      console.log("1111", row);
      window.location.href =
        "https://zjh5.gsemanager.cn/#/study-detail?id=" + row.id;
    },
    goWxAuth() {
      this.isShow = false;
      let ref = window.location.href;
      ref = Number(ref.substring(ref.indexOf("id=") + 3, ref.indexOf("#/")));
      let url = process.env.VUE_APP_WX_REDIRECTURL + "?p=huodong/" + ref;
      window.location.href =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxd0ed98b5f2e590fa&redirect_uri=" +
        url +
        "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
    },
    //   提交
    onSubmit() {
      let ref = window.location.href;
      ref = Number(ref.substring(ref.indexOf("id=") + 3, ref.indexOf("#/")));
      getsignin({ ActivityID: ref }).then((res) => {
        if (res.code == 200) {
          Notify({
            type: "success",
            message: "签到成功",
          });
        }
        this.getDetails();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.reset {
  height: 100vh;
  background: #eef1f6;
}
.tips {
  padding: 16px;
  color: #333;
  font-size: 14px;
}
.css1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
.css2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.css3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
</style>